import React from "react"
import { Link } from "gatsby"

const ReservationSubmitted = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center px-6">
      <div className="flex items-center justify-center flex-col">
        <div>
          <span className="uppercase text-3xl"> Thank You</span>
        </div>
        <div>
          <span className="text-base lg:text-lg">
            Thank You for booking with us. Our team will get in touch with you
            soon.
          </span>
        </div>
        <div className="py-2">
          <Link to="/">
            <div className="flex items-center justify-center">
              <span class="material-icons text-green-800 ">first_page</span>
              <span className="text-green-800 font-medium">Back Home</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ReservationSubmitted
