const getBungalowInfo = name => {
  switch (name) {
    case "modern":
      return {
        id: 1,
        name: "Modern Bungalow",
        rooms: 5,
        pricing: {
          single: 70,
          double: 80,
          twin: 80,
          triple: 120,
        },
      }

    case "african":
      return {
        id: 1,
        name: "African Bungalow",
        rooms: 5,
        pricing: {
          single: 90,
          double: 100,
          twin: 100,
          triple: 140,
        },
      }

    default:
      return {
        id: 1,
        name: "Modern Bungalow",
        rooms: 5,
        pricing: {
          single: 70,
          double: 80,
          twin: 80,
          triple: 120,
        },
      }
  }
}

export default getBungalowInfo
