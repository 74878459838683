import { check } from "prettier"
import React from "react"
import { prefixWord } from "../../utils"

const getPackageTitle = ({ bungalow, option }) =>
  `${bungalow} bungalow - ${option}`
const ReservationPackage = ({
  id,
  stayLength,
  rooms,
  option,
  bungalow,
  price,
  removePackage,
  checkIn,
  checkOut,
}) => {
  return (
    <div class=" my-2 p-2 border-gray-100 bg-white border rounded-lg text-sm flex items-center justify-between">
      <div className="flex items-center">
        <i class="material-icons text-yellow-800">king_bed</i>
        <div className="px-4">
          <span class="font-medium capitalize ">
            {getPackageTitle({ bungalow, option })}
          </span>
          <div className="flex items-center text-gray-500">
            {checkIn} <span class="material-icons">arrow_right_alt</span>
            {checkOut}
          </div>
          <div>
            <span>
              <span className="capitalize">
                {rooms} {option} {bungalow}
              </span>{" "}
              {prefixWord("bungalow", rooms, "s")} for {stayLength}{" "}
              {prefixWord("night", stayLength, "s")} on{" "}
              <b>USD {Number(price).toLocaleString()}.00</b>
            </span>
          </div>
        </div>
      </div>

      <button
        className="outline-none focus:outline-none"
        onClick={() => removePackage && removePackage(id)}
      >
        <i class="material-icons text-gray-800">cancel</i>
      </button>
    </div>
  )
}

export default ReservationPackage
