import getBungalowInfo from "./getBungalowInfo"

const options = {
  double: {
    id: 1,
    value: "double",
    label: "Double",
    description: "bungalow with 1 double bed for 2 person, ideally for couple",
  },

  twin: {
    id: 2,
    value: "twin",
    label: "Twin",
    description:
      "bungalow with 2 Twin bed for 2 person, ideally for people want to share same room",
  },

  single: {
    id: 3,
    value: "single",
    label: "Single",
    description: "bungalow with 1 bed for 1 person",
  },

  triple: {
    id: 4,
    value: "triple",
    label: "Triple",
    description:
      "bungalow with  1 double bed + 1 single bed. The perfect bungalow for family who want to share room with kids",
  },
}

const bungalows = {
  modern: {
    id: 2,
    value: "modern",
    label: "Modern",
    description:
      "Situated in the higher part of the resort, the bungalows, built with volcanic stones and are surrounded by flowers with wonderful perfume and exotic plants.",
  },

  african: {
    id: 1,
    value: "african",
    label: "African",
    description:
      "Located in the lower part of the domain, on the shores of Lake Kivu, the bungalows, in typical African style, and decorated with local craft are surrounded by a large garden lined with palm trees and exotic flowers",
  },
}

export { getBungalowInfo, bungalows, options }
