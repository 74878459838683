import React from "react"
import InputRange from "react-input-range"

import "../../styles/components/slider-range.css"

const SliderRange = ({ from, to, onChange }) => {
  const [value, setvalue] = React.useState(1)
  return (
    <InputRange
      maxValue={to}
      minValue={from}
      value={value}
      onChange={value => {
        onChange(value)
        setvalue(value)
      }}
    />
  )
}

SliderRange.defaultProps = {
  from: 1,
  to: 10,
  onChange: () => console.warn("Attach onChange event handler"),
}

export default SliderRange
